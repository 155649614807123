import { useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "screens/auth";
import PageNotFound from "screens/pageNotFound";
import WalletConnect from "screens/walletConnect"
function AuthRoutes() {
  const [auth, setAuth] = useState(false);
  return (
    <Routes>
      <Route path="/" element={<WalletConnect setAuth={setAuth}  />} />
      {/* <Route path="/" element={<Login setAuth={setAuth} />} /> */}
      <Route path="/login" element={<Login setAuth={setAuth} />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default AuthRoutes;
