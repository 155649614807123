import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import Users from "screens/users";
import Course from "screens/course";
import Transactions from "screens/transactions";
import ProtectedRoute from "utilities/protectedRoute";
import Layout from "components/layout";
import PageNotFound from "screens/pageNotFound";
import Rounds from "screens/rounds";
import Dashboard from "screens/dashboard";
import UsersCourse from "screens/usersCourse";
import UserForm from "screens/users/updateUser";
import RoundDetails from "screens/rounds/viewDetails";
import RoundForm from "screens/rounds/updateRound";
import CourseDetails from "screens/course/viewDetails";
import ViewUser from "screens/users/viewUser";
import ViewWallet from "screens/dashboard/viewWallet";
import { Retailers } from "screens/retailers";
import { Vouchers } from "screens/vouchers";

function AdminRoutes() {
  const { user: currentUser } = useSelector((state) => state.auth);
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          path="/"
          element={
            <ProtectedRoute isLoggedIn={currentUser?.token}>
              <Navigate to="/dashboard" replace />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute isLoggedIn={currentUser?.token}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/wallet"
          element={
            <ProtectedRoute isLoggedIn={currentUser?.token}>
              <ViewWallet />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit_user"
          element={
            <ProtectedRoute isLoggedIn={currentUser?.token}>
              <UserForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/:wallet_address"
          element={
            <ProtectedRoute isLoggedIn={currentUser?.token}>
              <ViewUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute isLoggedIn={currentUser?.token}>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="/course"
          element={
            <ProtectedRoute isLoggedIn={currentUser?.token}>
              <Course />
            </ProtectedRoute>
          }
        />
        <Route
          path="/view_course"
          element={
            <ProtectedRoute isLoggedIn={currentUser?.token}>
              <CourseDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users_course"
          element={
            <ProtectedRoute isLoggedIn={currentUser?.token}>
              <UsersCourse />
            </ProtectedRoute>
          }
        />

        <Route
          path="/transactions"
          element={
            <ProtectedRoute isLoggedIn={currentUser?.token}>
              <Transactions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/retailers"
          element={
            <ProtectedRoute isLoggedIn={currentUser?.token}>
              <Retailers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vouchers"
          element={
            <ProtectedRoute isLoggedIn={currentUser?.token}>
              <Vouchers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rounds"
          element={
            <ProtectedRoute isLoggedIn={currentUser?.token}>
              <Rounds />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit_round"
          element={
            <ProtectedRoute isLoggedIn={currentUser?.token}>
              <RoundForm />
            </ProtectedRoute>
          }
        />

        <Route
          path="/round_details"
          element={
            <ProtectedRoute isLoggedIn={currentUser?.token}>
              <RoundDetails />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default AdminRoutes;
