import { Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";

const calculateTotalTokens = (rounds) => {
  let rewardBalance = 0;

  if (!rounds?.length) return rewardBalance;

  rounds.forEach((e) => {
    const currToken = e?.wunderToken;

    let token = typeof currToken === "number" ? currToken : 0;

    rewardBalance += token;
  });
  return rewardBalance;
};

export const columns = [
  {
    field: "_id",
    headerName: "ID",
    width: 220,
    // flex: 1,
  },
  {
    field: "walletAddress",
    headerName: "Wallet Address",
    width: 350,
  },
  {
    field: "activeUser",
    headerName: "User Status",
    renderCell: (params) =>
      params?.row?.activeUser == 1 ? "Active" : "In-Active",
    type: "string",
  },
  {
    field: "round",
    headerName: "Reward Balance",
    renderCell: (params) => {
      const round = params?.row?.round;
      return calculateTotalTokens(round);
    },
    type: "number",
    width: 120,
  },
];

export const CoursesColumns = [
  {
    field: "_id",
    headerName: "Course ID",
    width: 220,
    renderCell: (params) => params?.row?._id,
    type: "string",
    // flex: 0.6,
  },
  {
    field: "course_id",
    headerName: "ID",
    width: 120,
    renderCell: (params) => params?.row?.course_id,
    type: "string",
    // flex: 0.6,
  },
  {
    field: "course_name",
    headerName: "Course Name",
    width: 120,
    renderCell: (params) => params?.row?.course_name,
    type: "string",
    // flex: 0.6,
  },

  {
    field: "holes",
    headerName: "Holes",
    width: 120,
    renderCell: (params) => params?.row?.holes,
    type: "string",
    // flex: 0.6,
  },
  {
    field: "par",
    headerName: "Par",
    width: 120,
    renderCell: (params) => params?.row?.par,
    type: "string",
    // flex: 0.6,
  },
  {
    field: "course_type",
    headerName: "Course Type",
    width: 120,
    renderCell: (params) => params?.row?.course_type,
    type: "string",
    // flex: 0.6,
  },
  {
    field: "course_architect",
    headerName: "Course Architect",
    width: 120,
    renderCell: (params) => params?.row?.course_architect,
    type: "string",
    // flex: 0.6,
  },
  {
    field: "open_date",
    headerName: "Open Date",
    width: 120,
    renderCell: (params) => params?.row?.open_date,
    type: "string",
    // flex: 0.6,
  },
  {
    field: "guest_policy",
    headerName: "Guest Policy",
    width: 120,
    renderCell: (params) => params?.row?.guest_policy,
    type: "string",
    // flex: 0.6,
  },
  {
    field: "weekday_price",
    headerName: "Weekday Price",
    width: 120,
    renderCell: (params) => params?.row?.weekday_price,
    type: "string",
    // flex: 0.6,
  },
  {
    field: "weekend_price",
    headerName: "Weekend Price",
    width: 120,
    renderCell: (params) => params?.row?.weekend_price,
    type: "string",
    // flex: 0.6,
  },
  {
    field: "twilight_price",
    headerName: "Twilight Price",
    width: 120,
    renderCell: (params) => params?.row?.twilight_price,
    type: "string",
    // flex: 0.6,
  },
  {
    field: "fairway",
    headerName: "Fairway",
    width: 120,
    renderCell: (params) => params?.row?.fairway,
    type: "string",
    // flex: 0.6,
  },
  {
    field: "green",
    headerName: "Green",
    width: 120,
    renderCell: (params) => params?.row?.green,
    type: "string",
    // flex: 0.6,
  },
  {
    field: "currency",
    headerName: "Currency",
    width: 120,
    renderCell: (params) => params?.row?.currency,
    type: "string",
    // flex: 0.6,
  },
];
export const CoursesUsers = [
  {
    field: "_id",
    headerName: "ID",
    width: 200,
    flex: 1,
  },
  {
    field: "walletAddress",
    headerName: "Wallet Address",
    renderCell: (params) => params?.row?.user?.walletAddress,
    width: 350,
    type: "string",
    flex: 1,
  },
  {
    field: "activeUser",
    headerName: "activeUser",
    width: 150,
    renderCell: (params) => params?.row?.user?.activeUser,
    type: "string",
    flex: 1,
  },
];
export const activeSessions = [
  {
    field: "walletAddress",
    headerName: "Wallet Address",
    width: 330,
    renderCell: (params) => params?.row?.user?.walletAddress,
    type: "string",
  },
  {
    field: "_id",
    headerName: "Round ID",
    width: 200,
  },
  {
    field: "course_name",
    headerName: "Course Name",
    width: 150,
    renderCell: (params) => params?.row?.golfCourse?.course_name,
    type: "string",
  },

  {
    field: "created",
    headerName: "Start Time",
    width: 200,
    valueFormatter: (params) =>
      moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
  },
  {
    field: "completedAt",
    headerName: "End Time",
    width: 200,
    valueFormatter: (params) =>
      moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
  },
  {
    field: "wunderToken",
    headerName: "$WUNDR Token",
    width: 150,
    renderCell: (params) => params?.row?.wonderToken,
  },
  {
    field: "status",
    headerName: "Round Status",
    width: 150,
    renderCell: (params) => params?.row?.status,
  },
  {
    field: "confirmation",
    headerName: "Transaction Status",
    width: 150,
    renderCell: (params) =>
      params?.row?.confirmation ? "completed" : "pending",
  },
];
export const dashboardSessions = [
  {
    field: "walletAddress",
    headerName: "Wallet Address",
    width: 325,
    renderCell: (params) => params?.row?.user?.walletAddress,
    type: "string",
  },
  {
    field: "_id",
    headerName: "Round ID",
    width: 220,
    flex: 1,
  },
  {
    field: "created",
    headerName: "Start Time",
    flex: 1,
    valueFormatter: (params) =>
      moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
  },
  {
    field: "completedAt",
    headerName: "End Time",
    flex: 1,
    valueFormatter: (params) =>
      moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
  },
];
export const columnTransactions = [
  {
    field: "walletAddress",
    headerName: "Wallet Address",
    width: 330,
    renderCell: (params) => params?.row?.user?.walletAddress,
    type: "string",
  },
  {
    field: "_id",
    headerName: "Round ID",
    width: 220,
  },
  {
    field: "golfCourse",
    headerName: "Course ID",
    width: 220,
  },
  {
    field: "wunderToken",
    headerName: "$WUNDR Tokens",
    width: 150,
  },
  {
    field: "completedAt",
    headerName: "Transaction Time",
    width: 225,
    valueFormatter: (params) =>
      moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
  },
  {
    field: "transactionHash",
    headerName: "Transaction Hash",
    width: 500,
  },
];
export const columnTransactionsForUser = [
  {
    field: "_id",
    headerName: "Round ID",
    width: 200,
  },
  {
    field: "course_name",
    headerName: "Course Name",
    width: 150,
    renderCell: (params) => params?.row?.golfCourse?.course_name,
    type: "string",
  },

  {
    field: "created",
    headerName: "Start Time",
    width: 200,
    valueFormatter: (params) =>
      moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
  },
  {
    field: "completedAt",
    headerName: "End Time",
    width: 200,
    valueFormatter: (params) =>
      moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
  },
  {
    field: "wunderToken",
    headerName: "$WUNDR Token",
    width: 150,
    renderCell: (params) => params?.row?.wonderToken,
  },
  {
    field: "status",
    headerName: "Round Status",
    width: 150,
    renderCell: (params) => params?.row?.status,
  },
  {
    field: "confirmation",
    headerName: "Transaction Status",
    width: 150,
    renderCell: (params) =>
      params?.row?.confirmation ? "completed" : "pending",
  },
];
