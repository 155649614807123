import { balanceOfABI } from 'abi/balanceOfABI';
import { infura_rpc_provider } from 'config/constants/api';

const ethers = require('ethers');
const provider = new ethers.providers.JsonRpcProvider(infura_rpc_provider);



const wunderparERC20 = process.env.REACT_APP_WUNDERPAR_ERC20
export const rewardPoolAddress = process.env.REACT_APP_REWARD_POOL_ADDRESS

const contract = new ethers.Contract(wunderparERC20, balanceOfABI, provider);

export async function getTokenBalance() {
    const result = await contract.balanceOf(rewardPoolAddress);
    const formattedResult = ethers.utils.formatEther(result);

    console.log("formattedResult => ", formattedResult);
    return formattedResult
}

