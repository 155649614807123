import React, { useState, useEffect } from "react";
import { Grid, Box, Tab, Tabs, Button, ListItem, Avatar, Typography, useTheme } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import Header from "components/header";
import { useGetUserQuery, useGetUserTransactionsQuery } from "app-redux/query/api";
import StatBox from "components/grid/statBox";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "components/grid/Toolbar";
import { activeSessions, columnTransactionsForUser } from "common/tableColumns";

export default function ViewUser() {
    const theme = useTheme();
    let { wallet_address } = useParams();
    const { data, isLoading } = useGetUserQuery({ wallet_address });
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [sort, setSort] = useState({});
    const [startDate, setStartDate] = React.useState("2023-1-10");
    const [endDate, setEndDate] = React.useState("");
    const [search, setSearch] = useState("");
    const [date, setDate] = React.useState("");
    const [searchInput, setSearchInput] = useState("");
    const [user_id, setUser_id] = useState(null)
    const transactions = useGetUserTransactionsQuery({
        page,
        pageSize,
        sort: JSON.stringify(sort),
        search,
        startDate,
        endDate,
        user_id
    });

    useEffect(() => {

        if (data?.user) {
            setUser_id(data?.user?._id)
        }
    }, [data])


    const getName = () => {
        const name = data?.user?.userName;
        if (name) return name
        return "User"
    }

    const getPhoto = () => {
        const photo = data?.user?.photoUrl;
        if (photo) return photo

        return "/static/avatar.png"
    }

    return (
        <Box m="1.5rem 2.5rem">
            {/* <Header title="User Profile" subtitle="View the user profile" /> */}
            <Grid container my="1.5rem" columnSpacing={0} rowSpacing={2}>
                <Grid item xs={2}  >
                    <Avatar
                        alt="User Avatar"
                        src={getPhoto()}
                        style={{
                            width: "100%",
                            height: "auto",
                        }}
                    />
                </Grid>
                <Grid item xs={8} ml="2rem">
                    <Header title={`${getName()} Profile`}
                        subtitle={wallet_address} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <StatBox
                        isFlexBetween={true}
                        title="Earned Wundar Token ($WUNDR)"
                        value={data?.games[0]?.wallet_balance
                            || 0}
                        increase="+14%"
                        description="Since last month"
                    />
                </Grid>
                <Grid item xs={12}>
                    <StatBox
                        isFlexBetween={true}
                        title="Pending Rewards"
                        value={data?.pendingRewards[0]?.PendingRewards
                            || 0}
                        increase="+14%"
                        description="Since last month"
                    />
                </Grid>
                <Grid item xs={12}>
                    <StatBox
                        isFlexBetween={true}
                        title="Total Played Games"
                        value={data?.games[0]?.games_played
                            || 0}
                        increase="+14%"
                        description="Since last month"
                    />
                </Grid>
                <Grid item xs={12}>
                    <StatBox
                        isFlexBetween={true}
                        title="Total Played Courses"
                        value={data?.games[0]?.courses_played
                            || 0}
                        increase="+14%"
                        description="Since last month"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Box
                        height="80vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: theme.palette.background.alt,
                                color: theme.palette.secondary[100],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: theme.palette.primary.light,
                            },
                            "& .MuiDataGrid-footerContainer": {
                                backgroundColor: theme.palette.background.alt,
                                color: theme.palette.secondary[100],
                                borderTop: "none",
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${theme.palette.secondary[200]} !important`,
                            },
                        }}
                    >
                        <DataGrid
                            loading={isLoading}
                            getRowId={(row) => row._id}
                            rows={(transactions.data && transactions.data.rounds) || []}
                            columns={columnTransactionsForUser}
                            rowCount={(data && data.total) || 0}
                            rowsPerPageOptions={[20, 50, 100]}
                            pagination
                            isRowSelectable={() => { }}
                            page={page}
                            pageSize={pageSize}
                            paginationMode="server"
                            sortingMode="server"
                            onPageChange={(newPage) => setPage(newPage)}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            onSortModelChange={(newSortModel) => setSort(...newSortModel)}
                            // components={{ Toolbar: DataGridCustomToolbar }}
                            componentsProps={{
                                toolbar: { searchInput, setSearchInput, setSearch },
                            }}
                        />
                    </Box>
                </Grid>

            </Grid>


        </Box>
    );
}
