import axios from "axios";
import { BASE_URL, Login_Auth } from "config/constants/api";

const login = (walletAddress, password) => {
  return axios
    .post(BASE_URL + Login_Auth, {
      walletAddress,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("userWunder", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("userWunder");
};

const authService = {
  login,
  logout,
};

export default authService;
