import { instance } from "services";

const getNonceByAddress = (id) =>
  new Promise((resolve, reject) => {
    instance
      .get(`auth/nonce/${id}`)
      .then(function (response) {
        //handle success
        resolve(response);
      })
      .catch(function (response) {
        //handle error
        reject(response);
      });
  });

const verifySignature = (signedValue, id) =>
  new Promise((resolve, reject) => {
    instance
      .post(`auth/verify/${id}`, { signature: signedValue })
      .then(function (response) {
        //handle success
        if (response.data.user.type == 1) {
          localStorage.setItem("userWunder", JSON.stringify(response.data));
          resolve(response);
        } else {
          reject({ err: { message: "only admins can access dashboard" } });
        }
      })
      .catch(function (response) {
        //handle error
        reject(response);
      });
  });

const nonceService = {
  getNonceByAddress,
  verifySignature,
};

export default nonceService;
