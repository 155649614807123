import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTheme } from '@emotion/react';

function createData(_id, course_id, course_name, holes, par, course_type, course_architect, open_date, guest_policy, weekday_price, weekend_price, twilight_price, fairway, green, currency) {
    return { _id, course_id, course_name, holes, par, course_type, course_architect, open_date, guest_policy, weekday_price, weekend_price, twilight_price, fairway, green, currency };
}

const rows = [
    createData("MAR-DO-0002-01",
        "MAR-DO-0002",
        " Gary Player ",
        "18",
        "72",
        "Oceanfront",
        "Gary Player",
        "2010",
        "Open",
        "750",
        "750",
        "750",
        "Paspalum",
        "Paspalum",
        "MAD",
    ),
];

export default function BulkTable() {
    const theme = useTheme();
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, backgroundColor: "#191F45" }} aria-label="simple table">
                <TableHead sx={{ backgroundColor: theme.palette.background.alt, color: theme.palette.secondary[100], }}>
                    <TableRow>
                        <TableCell>_id</TableCell>
                        <TableCell align="right">course_id</TableCell>
                        <TableCell align="right">course_name</TableCell>
                        <TableCell align="right">holes</TableCell>
                        <TableCell align="right">par</TableCell>
                        <TableCell align="right">course_type</TableCell>
                        <TableCell align="right">course_architect</TableCell>
                        <TableCell align="right">open_date</TableCell>
                        <TableCell align="right">guest_policy</TableCell>
                        <TableCell align="right">weekday_price</TableCell>
                        <TableCell align="right">weekend_price</TableCell>
                        <TableCell align="right">twilight_price</TableCell>
                        <TableCell align="right">fairway</TableCell>
                        <TableCell align="right">green</TableCell>
                        <TableCell align="right">currency</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: "#4d547d" }}>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row._id}
                            </TableCell>
                            <TableCell align="right">{row.course_id}</TableCell>
                            <TableCell align="right">{row.course_name}</TableCell>
                            <TableCell align="right">{row.holes}</TableCell>
                            <TableCell align="right">{row.par}</TableCell>
                            <TableCell align="right">{row.course_type}</TableCell>
                            <TableCell align="right">{row.course_architect}</TableCell>
                            <TableCell align="right">{row.open_date}</TableCell>
                            <TableCell align="right">{row.guest_policy}</TableCell>
                            <TableCell align="right">{row.weekday_price}</TableCell>
                            <TableCell align="right">{row.weekend_price}</TableCell>
                            <TableCell align="right">{row.twilight_price}</TableCell>
                            <TableCell align="right">{row.fairway}</TableCell>
                            <TableCell align="right">{row.green}</TableCell>
                            <TableCell align="right">{row.currency}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}