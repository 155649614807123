import React from "react";
import { Container, Typography, Link, Box, Divider } from "@mui/material";
import LoginForm from "../../components/loginForm";
import { motion } from "framer-motion";
import { RootStyle,HeadingStyle,ContentStyle } from "./styles";
import { easing,fadeInUp } from "utilities/animations";
import { login_account } from "config/constants/strings";


const Login = ({ setAuth }) => {
  return (
    <RootStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          <HeadingStyle component={motion.div} {...fadeInUp}>
            {/* <Logo /> */}
            <Typography sx={{ color: "text.secondary" }}>
              {login_account}
            </Typography>
          </HeadingStyle>

          <Divider sx={{ my: 2 }} component={motion.div} {...fadeInUp}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              OR
            </Typography>
          </Divider>

          <LoginForm setAuth={setAuth} />

        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default Login;