import { DataArray } from "@mui/icons-material";
import axios from "axios";
import { instance } from "services";

const updateProfile = (data, id) => {
  var { name, image } = data;
  var data = new FormData();
  data.append("image", image);
  data.append("username", name);

  instance
    .put(`user/user_update/${id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(function (response) {
      //handle success
      return response;
    })
    .catch(function (response) {
      //handle error
      console.log(response);
    });
};
const updateUserDetail = (data, id) => {
  var { earning, noOfCourse } = data;
  let detailsData = { earning, noOfCourse };
  instance
    .put(`user/${id}`, detailsData)
    .then(function (response) {
      //handle success
      return response;
    })
    .catch(function (response) {
      //handle error
      console.log(response);
    });
};

const userService = {
  updateProfile,
  updateUserDetail,
};

export default userService;
