import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import BulkTable from './bulkTable';
import { LoadingButton } from '@mui/lab';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: "#191F45",
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BulkCourseModal({ disabled, loading, handleFileSelect }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div >
            <Button
                variant="contained"
                component="label"
                sx={{ my: 2 }}
                onClick={handleOpen}>Upload Courses</Button>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <BulkTable />
                    <LoadingButton
                        disabled={disabled}
                        loading={loading}
                        variant="contained"
                        component="label"
                        sx={{ my: 2 }} >
                        Upload
                        <input hidden
                            type="file"
                            accept=".xls, .xlsx"
                            onChange={handleFileSelect}
                            multiple
                        />
                    </LoadingButton>
                </Box>
            </Modal>
        </div>
    );
}