export const styles = {
  boxContainer: {
    width: "100%",
    height: "100vh",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  innerContainer: {
    width: { xs: "320px", sm: "450px" },
    height: { xs: "320px", sm: "450px" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(255,255,255,0.7)",
  },
  typographyStyle: {
    fontSize: { xs: "150px", sm: "200px" },
    fontFamily: "Passion One",
    color: "#34303e",
  },
  typographyText: {
    fontSize: { xs: "20px", sm: "25px" },
    marginBottom: "15px",
  },
};
