import React, { useState, useEffect } from "react";
import { Grid, Box, Tab, Tabs, Button } from "@mui/material";
import Input from "components/inputField";
import { useForm, Form } from "hooks/useForm";
import UserService from "services/user.service";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export default function UserForm() {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const initialFValues = {
    name:
      location?.state?.user?.username != undefined
        ? location?.state?.user?.username
        : "",
    image:
      location?.state?.user?.photoUrl != undefined
        ? location?.state?.user?.photoUrl
        : "",
    noOfCourse:
      location?.state?.user?.user?.length > 0
        ? location?.state?.user?.user[0]?.noOfCourse
        : "",
    earning:
      location?.state?.user?.user?.length > 0
        ? location?.state?.user?.user[0]?.earning
        : "",
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmitProfile = async (e) => {
    e.preventDefault();
    if (value == 0) {
      let response = await UserService.updateProfile(
        values,
        location?.state?.user._id
      );
      toast.success("Successfully updated");
    } else {
      let response = await UserService.updateUserDetail(
        values,
        location?.state?.user._id
      );
      toast.success("Successfully updated");
    }
  };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box m="1.5rem 2.5rem">
      <Tabs
        value={value}
        onChange={handleChangeTab}
        aria-label="update form tabs"
      >
        <Tab label="Profile" />
        {/* <Tab label="Earnings" /> */}
      </Tabs>

      {value === 0 && (
        <Form onSubmit={handleSubmitProfile}>
          <Grid
            container
            spacing={2}
            sx={{
              "& .MuiTextField-root": {
                margin: 1,
              },
            }}
          >
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                mt: 5,
              }}
            >
              <Input
                name="name"
                label="User name"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
              />

              <Input
                type="file"
                name="file"
                id="file"
                onChange={handleInputChange}
                accept="image/*"
              />
              {location?.state?.user?.photoUrl && (
                <div>
                  <img
                    src={values.image}
                    alt="No Image"
                    style={{
                      width: "100%",
                      height: "auto"
                    }}
                  />
                </div>
              )}

              <Box
                sx={{
                  mt: 3,
                  ml: 1,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  text="Submit"
                >
                  Update
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
      {value === 1 && (
        <Form onSubmit={handleSubmitProfile}>
          <Grid
            container
            spacing={2}
            sx={{
              "& .MuiTextField-root": {
                margin: 1,
              },
            }}
          >
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                mt: 5,
              }}
            >
              <Input
                name="noOfCourse"
                label="Earning"
                value={values.noOfCourse}
                onChange={handleInputChange}
                error={errors.noOfCourse}
              />
              <Input
                name="earning"
                label="Earnings"
                value={values.earning}
                onChange={handleInputChange}
                error={errors.earning}
              />
              <Box
                sx={{
                  mt: 3,
                  ml: 1,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  text="Submit"
                >
                  Update
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Box>
  );
}
