import React, { useState, useEffect } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";

import FlexBetween from "common/styles";
import Header from "components/header";
import { useLocation } from "react-router-dom";
import TypographyBox from "components/typography";

const CourseDetails = () => {
  const theme = useTheme();
  const location = useLocation();

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="Course" subtitle="Selected Course Details" />
      </FlexBetween>

      <Box
        gridColumn="span 8"
        gridRow="span 2"
        backgroundColor={theme.palette.background.alt}
        p="1rem"
        borderRadius="0.55rem"
        marginTop={2}
      >
        <Box sx={{ width: "100%", maxWidth: 500 }}>
          <Typography variant="h1" gutterBottom>
            {location?.state?.course?.club_name}
          </Typography>
          <TypographyBox
            heading={"Address"}
            subText={location?.state?.course?.address}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Caddie Hire"}
            subText={location?.state?.course?.caddie_hire}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Changing Room"}
            subText={location?.state?.course?.changing_room}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Chipping Green"}
            subText={location?.state?.course?.chipping_green}
            bold={"h6"}
          />
          <TypographyBox
            heading={"City"}
            subText={location?.state?.course?.city}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Club Fitting"}
            subText={location?.state?.course?.club_fitting}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Club Id"}
            subText={location?.state?.course?.club_id}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Club Membership"}
            subText={location?.state?.course?.club_membership}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Club Name"}
            subText={location?.state?.course?.club_name}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Contact Name"}
            subText={location?.state?.course?.contact_name}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Contact Title"}
            subText={location?.state?.course?.contact_title}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Country"}
            subText={location?.state?.course?.country}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Driving Range"}
            subText={location?.state?.course?.driving_range}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Email Address"}
            subText={location?.state?.course?.email_address}
            bold={"h6"}
          />
          <TypographyBox
            heading={"No Of Holes"}
            subText={location?.state?.course?.number_of_holes}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Phone"}
            subText={location?.state?.course?.phone}
            bold={"h6"}
          />
          <TypographyBox
            heading={"State"}
            subText={location?.state?.course?.state}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Phone"}
            subText={location?.state?.course?.phone}
            bold={"h6"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CourseDetails;
