import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetUsersQuery } from "app-redux/query/api";
import Header from "components/header";
import DataGridCustomToolbar from "components/grid/Toolbar";
import { columns } from "common/tableColumns";
import { useNavigate } from "react-router-dom";
import "./styles.css"
const Users = () => {
  const theme = useTheme();

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");
  const { data, isLoading } = useGetUsersQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort),
    search,
    count: false
  });
  const handleWalletClick = (e) => {
    console.log("e => ", e);
    if (e.field !== "_id") return
    navigate(`/user/${e.row?.walletAddress}`)

  }

  console.log("data => ", data)
  useEffect(() => {
    setSearch(searchInput);
  }, [searchInput]);
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Users" subtitle="Entire list of users" />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          getRowId={(row) => row._id}
          rows={(data && data.users) || []}
          columns={columns}
          rowCount={search == "" ? data?.total : data?.users?.length}
          onCellClick={(e) => handleWalletClick(e)}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          isRowSelectable={() => { }}
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
        />
      </Box>
    </Box>
  );
};

export default Users;
