import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function BasicSelect({
  handleChange,
  session,
  dropdownText,
  width,
  ml,
  mt,
  label,
}) {
  return (
    <Box sx={{ minWidth: width, maxWidth: width }} mt={mt} ml={ml}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={session}
          label={label}
          onChange={handleChange}
        >
          <MenuItem value={10}>{dropdownText.first}</MenuItem>
          <MenuItem value={20}>{dropdownText.second}</MenuItem>
          {dropdownText.third && <MenuItem value={30}>{dropdownText.third}</MenuItem>}
          {dropdownText.fourth && <MenuItem value={40}>{dropdownText.fourth}</MenuItem>}
          {dropdownText.fifth && <MenuItem value={50}>{dropdownText.fifth}</MenuItem>}
        </Select>
      </FormControl>
    </Box>
  );
}
