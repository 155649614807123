import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import Header from "components/header";
import { useGetAllRetailersQuery } from "app-redux/query/api";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import "./styles.css";
import { CreateRetailer } from "./createRetailer";

const columns = [
  {
    field: "_id",
    headerName: "Retailer ID",
    width: 220,
  },
  {
    field: "name",
    headerName: "Retailer Name",
    width: 220,
  },
  {
    field: "logoUrl",
    headerName: "Retailer Logo",
    width: 150,
    renderCell: (params) => (
      <img className="retailerLogo" src={params.value} alt={params.value} />
    ),
  },
  {
    field: "website",
    headerName: "Retailer Website",
    width: 220,
    renderCell: (params) => (
      <a target="_blank" href={params.value} rel="noreferrer">
        {params.value}
      </a>
    ),
  },
];

export function Retailers() {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { data, isLoading } = useGetAllRetailersQuery({
    page: page,
    pageSize: pageSize,
  });
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Retailers" subtitle="Entire list of retailers" />
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          sx={{ marginTop: 2 }}
          variant="contained"
          component="label"
          onClick={() => setModalOpen(true)}
        >
          Create Retailer
        </Button>
      </Box>

      <CreateRetailer open={modalOpen} onClose={() => setModalOpen(false)} />
      <Box
        mt={2}
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          page={page}
          pageSize={pageSize}
          rows={(data && data.retailers) || []}
          columns={columns}
          getRowId={(row) => row._id}
          disableRowSelectionOnClick
          paginationMode="server"
          sortingMode="server"
          pagination
          rowsPerPageOptions={[10, 20]}
          rowCount={(data && data.totalRowCount) || 0}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        />
      </Box>
    </Box>
  );
}
