import React, { useEffect, useState } from "react";
import {
  DownloadOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import FlexBetween from "common/styles";
import Header from "components/header";
import {
  useGetAllSessionsQuery,
  useGetCoursesQuery,
  useGetDashboardQuery,
  useGetUsersQuery,
} from "app-redux/query/api";
import StatBox from "components/grid/statBox";
import { activeSessions, dashboardSessions } from "common/tableColumns";
import { useNavigate } from "react-router-dom";
import "./styles.css"

const Dashboard = () => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [sessionCount, setSessionCount] = useState(0);
  const navigate = useNavigate();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [startDate, setStartDate] = React.useState("2023-1-10");
  const [endDate, setEndDate] = React.useState("");
  const { data, isLoading } = useGetUsersQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort),
    search,
    count: true,
  });
  const { data: gameSessions, isLoading: activeIsLoading } =
    useGetAllSessionsQuery({
      page,
      pageSize,
      sort: JSON.stringify(sort),
      search,
      startDate,
      endDate
    });
  const courses = useGetCoursesQuery({ count: true });
  console.log("courses => ", courses.data);
  const handleWalletClick = (e) => {
    console.log("e => ", e);
    if (e.field !== "walletAddress") return
    navigate(`/user/${e.row?.user?.walletAddress}`)

  }

  console.log("game sessions => ", gameSessions)
  useEffect(() => {

    const count = gameSessions?.rounds?.filter(r => r?.confirmation === true)?.length
    if (count)
      setSessionCount(count)

  }, [gameSessions])
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
      </FlexBetween>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* ROW 1 */}
        <StatBox
          title="Total Users"
          value={data?.total || 0}
          increase="+14%"
          description="Since last month"
        />
        <StatBox
          title="Total Number of Rounds"
          value={gameSessions?.total}
          increase="+21%"
          description="Since last month"
        />
        <StatBox
          title="Total Number of Transactions"
          value={sessionCount}
          increase="+21%"
          description="Since last month"
        />
        <StatBox
          title="Total Number of Courses"
          value={courses?.data?.data?.total}
          increase="+21%"
          description="Since last month"
        />
        <Box
          gridColumn="span 12"
          gridRow="span 3"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={activeIsLoading || !gameSessions}
            getRowId={(row) => row._id}
            isRowSelectable={() => { }}
            rows={(gameSessions && gameSessions.rounds) || []}
            onCellClick={(e) => handleWalletClick(e)}
            columns={activeSessions}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
