import { configureStore } from "@reduxjs/toolkit";
import { api } from "../query/api";
import authReducer from "../slices/auth";
import messageReducer from "../slices/message";
import globalReducer from "../index";
export const store = configureStore({
    reducer: {
      global: globalReducer,
      auth: authReducer,
      message: messageReducer,
      [api.reducerPath]: api.reducer,
    },
    middleware: (getDefault) => getDefault().concat(api.middleware),
  });