import React from "react";
import Modal from "@mui/material/Modal";
import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useCreateRetailerMutation } from "app-redux/query/api";
import Snackbar from "@mui/material/Snackbar";

export function CreateRetailer(props) {
  const { open, onClose } = props;
  const [snackBarOpen, setSnackBartOpen] = React.useState(false);
  const [createRetailer] = useCreateRetailerMutation();
  const formik = useFormik({
    initialValues: {
      name: "",
      logoUrl: "",
      website: "",
    },
    onSubmit: async (values) => {
      await createRetailer(values);
      setSnackBartOpen(true);
      onClose();
    },
  });

  const handleClose = () => {
    setSnackBartOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            boxShadow: 24,
            p: 4,
            bgcolor: "#191F45",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Create a retailer
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{ my: 2, display: "flex", flexDirection: "column", gap: 2 }}
            >
              <TextField
                label="Name"
                variant="standard"
                id="name"
                onChange={formik.handleChange}
              />
              <TextField
                label="Logo URL"
                variant="standard"
                id="logoUrl"
                onChange={formik.handleChange}
              />
              <TextField
                label="Website"
                variant="standard"
                id="website"
                onChange={formik.handleChange}
              />
            </Box>
            <Box sx={{ justifyContent: "end", display: "flex" }}>
              <Button
                variant="contained"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? "Saving..." : "Save"}
              </Button>
              <Button sx={{ ml: 2 }} variant="outlined" onClick={onClose}>
                Close
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackBarOpen}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Retailer created successfully!
        </Alert>
      </Snackbar>
    </>
  );
}
