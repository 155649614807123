import React, { useState, useEffect } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import { read, utils } from 'xlsx';
import { DataGrid } from "@mui/x-data-grid";
import { useBulkUploadCoursesMutation, useGetCountriesQuery, useGetCoursesQuery } from "app-redux/query/api";
import Header from "components/header";
import DataGridCustomToolbar from "components/grid/Toolbar";
import { CoursesColumns } from "common/tableColumns";
import "./style.css"
import BulkCourseModal from "components/bulkCourseModal";


const sortAlphabetically = (arr) => {


  const sortedArray = [...arr];

  console.log("sorted array => ", arr)
  return sortedArray?.sort(function (a, b) {
    var nameA = a.country.toUpperCase();
    var nameB = b.country.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}

const Course = () => {
  const theme = useTheme();

  // values to be sent to the backend
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [country, setCountry] = useState("")
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [addCourses, response] = useBulkUploadCoursesMutation();
  const { data: countries } = useGetCountriesQuery()

  const [searchInput, setSearchInput] = useState("");
  const { data, isLoading } = useGetCoursesQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort),
    search,
    count: false
  });


  function readExcelData(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = utils.sheet_to_json(worksheet);
      // Send the JSON data to the server
      sendDataToServer(jsonData);
    };
    reader.readAsArrayBuffer(file);
  }


  function sendDataToServer(data) {
    addCourses(data)
      .unwrap()
      .then((data) => { console.log("data => ", data) })
      .then((error) => {
        console.log(error)
      }).finally(() => {
        console.log("jsonData => ", data);
        setLoading(false)
      })

  }

  const handleFileSelect = (e) => {
    setLoading(true)
    setFile(e.target.files[0]);
    // send the file to the server
    console.log("send the file to the server");
    readExcelData(e.target.files[0]);
  };

  console.log("sort => ", sortAlphabetically(countries?.data || []));


  useEffect(() => {
    setSearch(searchInput);
  }, [searchInput]);

  const renderCountriesList = () => {

    return (countries?.data || []) && sortAlphabetically(countries?.data || [])?.map(c => <MenuItem
      key={c.country}
      value={c.country}>{c.country}</MenuItem>)

  }

  const renderCountries = () => <div>  <FormControl
    sx={{ marginTop: 1, minWidth: 144 }}
  // fullWidth
  >
    <InputLabel id="demo-simple-select-label">Countries</InputLabel>
    <Select

      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={country}
      label="Countries"
      onChange={e => setCountry(e.target.value)}
    >
      {renderCountriesList()}
    </Select>
  </FormControl></div>

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Course" subtitle="Entire list of course" />
      {renderCountries()}
      <BulkCourseModal
        disabled={loading}
        loading={response.isLoading}
        handleFileSelect={handleFileSelect}
      />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          {...data?.courses}
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data?.data?.courses) || []}
          columns={CoursesColumns}
          rowCount={search == "" ? data?.data?.total : data?.data?.courses?.length}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          isRowSelectable={() => { }}
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
        />
      </Box>
    </Box>
  );
};

export default Course;
