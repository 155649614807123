import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  active_game_sessions,
  count_users,
  courses_list,
  users_course,
  user_list,
  user_transactions,
  get_user,
  transactions_byUser,
  courses_bulk_upload,
  cool_down,
  countries,
  retailer_list,
  voucher_list,
} from "config/constants/api";

const prepHeader = (headers, { getState }) => {
  const token = getState().auth?.user?.token;

  // If we have a token set in state, let's assume that we should be passing it.
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }

  return headers;
};

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth?.user?.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  reducerPath: "adminApi",
  tagTypes: ["Users", "Courses"],
  endpoints: (build) => ({
    getUsers: build.query({
      query: ({ page, pageSize, sort, search, count = false }) => ({
        url: user_list,
        method: "GET",
        params: { page, pageSize, sort, search, count },
      }),
      providesTags: ["Users"],
    }),
    bulkUploadCourses: build.mutation({
      query: (payload) => ({
        url: courses_bulk_upload,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Post"],
    }),

    getUser: build.query({
      query: ({ wallet_address }) => ({
        url: `${get_user}${wallet_address}`,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
    getCourses: build.query({
      query: ({ page, pageSize, sort, search, count = false }) => ({
        url: courses_list,
        method: "GET",
        params: { page, pageSize, sort, search, count },
      }),
      providesTags: ["Users"],
    }),
    getActiveSessions: build.query({
      query: ({
        page,
        pageSize,
        sort,
        search,
        session,
        startDate,
        endDate,
      }) => ({
        url: active_game_sessions,
        method: "GET",
        params: { page, pageSize, sort, search, session, startDate, endDate },
      }),
      providesTags: ["Users"],
    }),
    getDashboard: build.query({
      query: () => ({
        url: count_users,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
    getAllSessions: build.query({
      query: ({ page, pageSize, sort, search, all, startDate, endDate }) => ({
        url: active_game_sessions,
        method: "GET",
        params: { page, pageSize, sort, search, all, startDate, endDate },
      }),
      providesTags: ["Users"],
    }),
    getCountries: build.query({
      query: () => ({
        url: countries,
        method: "GET",
      }),
      providesTags: ["Courses"],
    }),
    getCoolDown: build.query({
      query: () => ({
        url: cool_down,
        method: "GET",
      }),
      providesTags: ["CoolDown"],
    }),
    updateCoolDown: build.mutation({
      query: ({ id, coolDown }) => ({
        url: `${cool_down}/${id}`,
        method: "PUT",
        body: { coolDown },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Post"],
    }),
    getAllUsersCourses: build.query({
      query: ({ page, pageSize, sort, search, all, _id }) => ({
        url: users_course,
        method: "GET",
        params: { page, pageSize, sort, search, all, _id },
      }),
      providesTags: ["Users"],
    }),
    getUserTransactions: build.query({
      query: ({
        page,
        pageSize,
        sort,
        search,
        startDate,
        endDate,
        user_id,
      }) => {
        if (!user_id) return;
        return {
          url: `${transactions_byUser}/${user_id}`,
          method: "GET",
          params: { page, pageSize, sort, search, startDate, endDate },
        };
      },
      providesTags: ["Users"],
    }),
    getAllUserTransactions: build.query({
      query: ({
        page,
        pageSize,
        sort,
        search,
        startDate,
        endDate,
        pending,
      }) => ({
        url: user_transactions,
        method: "GET",
        params: { page, pageSize, sort, search, startDate, endDate, pending },
      }),
      providesTags: ["Users"],
    }),
    getAllRetailers: build.query({
      query: ({ page, pageSize }) => ({
        url: retailer_list,
        method: "GET",
        params: { page, pageSize },
      }),
      providesTags: ["Retailers"],
    }),
    getAllVouchers: build.query({
      query: ({ page, pageSize }) => ({
        url: voucher_list,
        method: "GET",
        params: { page, pageSize },
      }),
      providesTags: ["Vouchers"],
    }),
    searchRetailers: build.mutation({
      query: ({ page, pageSize, search }) => ({
        url: retailer_list,
        method: "GET",
        params: { page, pageSize, search },
      }),
    }),
    createRetailer: build.mutation({
      query: (payload) => ({
        url: retailer_list,
        method: "POST",
        body: payload,
      }),
      providesTags: ["createRetailer"],
      invalidatesTags: ["Retailers"],
    }),
    createVoucher: build.mutation({
      query: (payload) => ({
        url: voucher_list,
        method: "POST",
        body: payload,
      }),
      providesTags: ["createVoucher"],
      invalidatesTags: ["Vouchers"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetCountriesQuery,
  useGetCoolDownQuery,
  useUpdateCoolDownMutation,
  useBulkUploadCoursesMutation,
  useGetUserQuery,
  useGetCoursesQuery,
  useGetActiveSessionsQuery,
  useGetDashboardQuery,
  useGetAllSessionsQuery,
  useGetAllUsersCoursesQuery,
  useGetAllUserTransactionsQuery,
  useGetUserTransactionsQuery,
  useGetAllRetailersQuery,
  useGetAllVouchersQuery,
  useCreateRetailerMutation,
  useSearchRetailersMutation,
  useCreateVoucherMutation,
} = api;
