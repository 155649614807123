export const BASE_URL = `${process.env.REACT_APP_BASE_URL}api/`;
export const Login_Auth = "auth/login";
export const user_list = "admin/api/user/";
export const get_user = "admin/api/user/";
export const user_transactions = "admin/api/user/transactions";
export const retailer_list = "admin/api/retailer";
export const voucher_list = "admin/api/voucher";
export const transactions_byUser = "admin/api/round/by_user";
export const courses_list = "admin/api/course/";
export const countries = "api/course/countries";
export const cool_down = "admin/api/coolDown/";
export const update_cool_down = "admin/api/coolDown/";
export const courses_bulk_upload = "admin/api/course/bulk_upload";
export const active_game_sessions = "admin/api/round/active_sessions";
export const count_users = "api/user/count_users";
export const users_course = "api/course/course_users";
export const infura_rpc_provider = `${process.env.REACT_APP_INFURA_RPC_URL}`;
