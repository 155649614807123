import { DataArray } from "@mui/icons-material";
import axios from "axios";
import { instance } from "services";

const getCourseById = (id) =>
  new Promise((resolve, reject) => {
    instance
      .get(`course/${id}`)
      .then(function (response) {
        //handle success
        resolve(response);
      })
      .catch(function (response) {
        //handle error
        reject(response);
      });
  });

const courseService = {
  getCourseById,
};

export default courseService;
