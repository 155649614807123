import {
  Alert,
  Box,
  Button,
  Modal,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  useGetAllVouchersQuery,
  useSearchRetailersMutation,
  useCreateVoucherMutation,
} from "app-redux/query/api";
import Header from "components/header";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import { debounce } from "@mui/material/utils";
import { useFormik } from "formik";

const columns = [
  {
    field: "_id",
    headerName: "Voucher ID",
    width: 220,
  },
  {
    field: "retailer",
    headerName: "Retailer Name",
    width: 180,
    renderCell: (params) => params.value.name,
  },
  {
    field: "code",
    headerName: "Redeem Code",
    width: 180,
  },
  {
    field: "wundrValue",
    headerName: "Wundr Value",
    width: 100,
  },
  {
    field: "fiatCurrency",
    headerName: "Currency",
    width: 100,
  },
  {
    field: "fiatValue",
    headerName: "Currency Value",
    width: 100,
  },
  {
    field: "state",
    headerName: "Status",
    width: 80,
  },
];

export function Vouchers() {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const { data, isLoading } = useGetAllVouchersQuery({
    page: page,
    pageSize: pageSize,
  });

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Vouchers" subtitle="Entire list of vouchers" />
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          sx={{ marginTop: 2 }}
          variant="contained"
          component="label"
          onClick={() => setModalOpen(true)}
        >
          Create Voucher
        </Button>
      </Box>
      <Box
        mt={2}
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          page={page}
          pageSize={pageSize}
          rows={(data && data.vouchers) || []}
          columns={columns}
          getRowId={(row) => row._id}
          disableRowSelectionOnClick
          paginationMode="server"
          sortingMode="server"
          pagination
          rowsPerPageOptions={[10, 20]}
          rowCount={(data && data.totalRowCount) || 0}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        />
      </Box>
      <CreateVoucherModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Box>
  );
}

function CreateVoucherModal(props) {
  const { open = false, onClose } = props;
  const [searchVouchers] = useSearchRetailersMutation();
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null);
  const [snackBarOpen, setSnackBartOpen] = useState(false);
  const [createVoucher] = useCreateVoucherMutation();
  const formik = useFormik({
    initialValues: {
      countryCode: "",
      fiatValue: "",
      fiatCurrency: "",
      code: "",
      wundrValue: "",
      retailerId: null,
    },
    onSubmit: async (values) => {
      console.log({ values });
      await createVoucher(values);
      //   setSnackBartOpen(true);
      onClose();
    },
  });

  const debouncedSearchVouchers = React.useMemo(
    () =>
      debounce((request, callback) => {
        searchVouchers(request).then(({ data }) => callback(data));
      }, 400),
    [searchVouchers]
  );

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    debouncedSearchVouchers(
      { search: inputValue, page: 0, pageSize: 10 },
      (results) => {
        if (active) {
          let newOptions = [];

          //   if (value) {
          //     newOptions = [value];
          //   }

          if (results) {
            newOptions = [...newOptions, ...(results.retailers || [])];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [debouncedSearchVouchers, inputValue, value]);

  const handleClose = () => {
    setSnackBartOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 720,
            boxShadow: 24,
            width: "calc(100% - 64px)",
            p: 4,
            bgcolor: "#191F45",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Create a voucher
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{ my: 2, display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Autocomplete
                // sx={{ width: 300 }}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                noOptionsText="No retailers"
                onChange={(event, newValue) => {
                  // console.log({newValue})
                  // setOptions(newValue ? [newValue, ...options] : options);
                  setValue(newValue);
                  formik.setFieldValue("retailerId", newValue?._id || null);
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                isOptionEqualToValue={(option, value) => {
                  console.log("check", option._id === value._id);
                  return option._id === value._id;
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option._id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Add a retailer"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
              <TextField
                id="countryCode"
                label="Country code"
                variant="standard"
                onChange={formik.handleChange}
              />
              <TextField
                id="fiatValue"
                label="Fiat value"
                variant="standard"
                onChange={formik.handleChange}
              />
              <TextField
                id="fiatCurrency"
                label="Fiat currency"
                variant="standard"
                onChange={formik.handleChange}
              />
              <TextField
                id="code"
                label="Voucher code"
                variant="standard"
                onChange={formik.handleChange}
              />
              <TextField
                id="wundrValue"
                label="Wundr value"
                variant="standard"
                onChange={formik.handleChange}
              />
            </Box>
            <Box sx={{ justifyContent: "end", display: "flex" }}>
              <Button
                variant="contained"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? "Saving..." : "Save"}
              </Button>
              <Button sx={{ ml: 2 }} variant="outlined" onClick={onClose}>
                Close
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackBarOpen}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Voucher created successfully!
        </Alert>
      </Snackbar>
    </>
  );
}
