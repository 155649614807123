import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { themeSettings } from "utilities/theme";
import AdminRoutes from "routes/admin";
import AuthRoutes from "routes/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const { user: currentUser } = useSelector((state) => state.auth);
  console.log("ENV RPC", process.env.REACT_APP_INFURA_RPC_URL);
  return (
    <div className="app">
      <BrowserRouter>
        {!currentUser?.token ? (
          <AuthRoutes />
        ) : (
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AdminRoutes />
          </ThemeProvider>
        )}
      </BrowserRouter>
      <ToastContainer limit={2} autoClose={1000} theme={"dark"} />
    </div>
  );
}

export default App;
