import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";

export default function TypographyBox(props) {
  const { heading, subText, bold } = props;
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="row" alignItems={"center"}>
      <Typography
        variant="h4"
        gutterBottom
        color={theme.palette.secondary[300]}
      >
        {heading} :
      </Typography>
      <Typography variant={bold} gutterBottom marginLeft={2}>
        {subText}
      </Typography>
    </Box>
  );
}
