import { DataArray } from "@mui/icons-material";
import axios from "axios";
import { instance } from "services";

const updateRoundDetail = (data, id,session) => {
  var { holeCount,activeSession } = data;
  let detailsData = { holeCount,activeSession,session };
  instance
    .put(`round/${id}`, detailsData)
    .then(function (response) {
      //handle success
      return response;
    })
    .catch(function (response) {
      //handle error
      console.log(response);
    });
};

const roundService = {
  updateRoundDetail,
};

export default roundService;
