import React from "react";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { Paper } from "@mui/material";
import { styles } from "./styles";
import { page_not_found, page_not_found_404 } from "config/constants/strings";

const NotFound = () => {
  return (
    <Box sx={styles.boxContainer}>
      <Paper sx={styles.innerContainer}>
        <Typography variant="h1" sx={styles.typographyStyle}>
          {page_not_found_404}
        </Typography>
        <Typography variant="h5" sx={styles.typographyText}>
          {page_not_found}
        </Typography>
      </Paper>
    </Box>
  );
};

export default NotFound;
