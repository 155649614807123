import React, { useState, useEffect } from "react";
import { Grid, Box, Tab, Tabs, Button } from "@mui/material";
import Input from "components/inputField";
import { useForm, Form } from "hooks/useForm";
import { useLocation } from "react-router-dom";
import FlexBetween from "common/styles";
import Header from "components/header";
import DropDownPicker from "components/dropDownPicker";
import RoundService from "services/round.service";
import { toast } from "react-toastify";
export default function RoundForm() {
  const [value, setValue] = React.useState(0);
  const [session, setSession] = React.useState("");
  const location = useLocation();
  var dropdownText = {
    first: "start",
    second: "half-completed",
    third: "completed",
  };
  const initialFValues = {
    holeCount:
      location?.state?.round?.holeCount != undefined
        ? location?.state?.round?.holeCount
        : "",
    activeSession:
      location?.state?.round?.activeSession != undefined
        ? location?.state?.round?.activeSession
        : "",
    result: location?.state?.round?.result
      ? location?.state?.round?.result
      : "",
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmitProfile = async (e) => {
    e.preventDefault();

    let response = await RoundService.updateRoundDetail(
      values,
      location?.state?.round._id,
      session
    );
    toast.success("Successfully updated");
  };

  const handlePickerChange = (event) => {
    setSession(event.target.value);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="Round" subtitle="Update Selected Round" />
      </FlexBetween>
      <Form onSubmit={handleSubmitProfile}>
        <Grid
          container
          spacing={2}
          sx={{
            "& .MuiTextField-root": {
              margin: 1,
            },
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              mt: 5,
            }}
          >
            <Input
              name="holeCount"
              label="Holes Count"
              value={values.holeCount}
              onChange={handleInputChange}
              error={errors.holeCount}
            />
            <Input
              name="activeSession"
              label="Active Session"
              value={values.activeSession}
              onChange={handleInputChange}
              error={errors.activeSession}
            />
            {/* <Input
                name="result"
                label="Result"
                value={values.result}
                onChange={handleInputChange}
                error={errors.result}
              /> */}
            <DropDownPicker
              handleChange={(event) => handlePickerChange(event)}
              session={session}
              dropdownText={dropdownText}
              width={215}
              ml={1}
              mt={1}
              label={"Result"}
            />
            <Box
              sx={{
                mt: 3,
                ml: 1,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                text="Submit"
              >
                Update
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
}
