import React, { useState, useEffect, useCallback } from "react";
import { Grid, Box, Tab, Tabs, Button, ListItem, Avatar, Typography, TextField, CircularProgress, LinearProgress, useTheme, Badge } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import Header from "components/header";
import { useGetCoolDownQuery, useGetUserQuery, useUpdateCoolDownMutation } from "app-redux/query/api";
import StatBox from "components/grid/statBox";
import { getTokenBalance, rewardPoolAddress } from "utilities/poolBalance";
import { LoadingButton } from "@mui/lab";

let timeoutId;
export default function ViewWallet() {
    const theme = useTheme();

    const [tokenBalance, setTotalBalance] = useState("");
    const [coolDown, setCoolDown] = useState();
    const [defaultCoolDown, setDefaultCoolDown] = useState({})
    const { data: cool } = useGetCoolDownQuery();
    const [updateCoolDown, responseCoolDown] = useUpdateCoolDownMutation();

    useEffect(() => {
        const coolDownObject = cool?.data[0];

        if (coolDownObject) {
            setDefaultCoolDown(coolDownObject)
            if (!coolDown)
                setCoolDown(coolDownObject?.coolDownHours)
        }

    }, [cool])

    function updateCoolDownFn() {
        let id = defaultCoolDown?._id
        if (!id) return
        updateCoolDown({ id, coolDown })
            .then(({ data }) => setDefaultCoolDown(data?.data))
            .catch(err => console.log("err => ", err))
    }

    const fetchBalance = async () => {
        try {
            const balance = await getTokenBalance();
            console.log("balance => ", balance)

            setTotalBalance(balance);

        } catch (error) {
            console.log("something went wrong while fetching the balance", error);
        }
    }
    useEffect(() => {
        fetchBalance()
    }, [])

    const getName = () => {
        return "Admin"
    }

    const getPhoto = () => {
        return "/static/avatar.png"
    }

    return (
        <Box m="1.5rem 2.5rem">
            {/* <Header title="User Profile" subtitle="View the user profile" /> */}
            <Grid container my="1.5rem" columnSpacing={0} rowSpacing={2}>
                <Grid item xs={2}  >
                    <Avatar
                        alt="User Avatar"
                        src={getPhoto()}
                        style={{
                            width: "100%",
                            height: "auto",
                        }}
                    />
                </Grid>
                <Grid item xs={8} ml="2rem">
                    <Grid xs={12}>
                        <Header title={`${getName()} Wallet Address`}
                            subtitle={"0x6a56C11372FC4b9D498AA0D75d4401F22eB414a6"} />
                    </Grid>
                    <Grid xs={12}>
                        <Header
                            admin={true}
                            title={`Reward Pool Address`}
                            subtitle={rewardPoolAddress} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid
                    backgroundColor={theme.palette.background.alt}
                    item
                    xs={12} mt={2}>
                    <Typography variant="h5" sx={{ color: theme.palette.secondary[100] }}>
                        Reward Pool Balance
                    </Typography>
                    <Typography
                        variant="h3"
                        py={"0.15rem"}
                        fontWeight={"200"}
                        m={"auto"}
                        sx={{ color: theme.palette.secondary[200] }}
                    >
                        {tokenBalance || 0}
                    </Typography>
                </Grid>
                <Grid item xs={3} mt={2} px={2} backgroundColor={theme.palette.background.alt}>
                    <Grid item xs={12}>
                        {/* <Badge
                            color="secondary" badgeContent={defaultCoolDown?.coolDownHours || 2}> */}
                        <Typography py={1} variant="h5" sx={{ color: theme.palette.secondary[100] }}>
                            Select Cool Down Hours
                        </Typography>
                        {/* </Badge> */}
                    </Grid>
                    <Grid my={2} item xs={12}>
                        <TextField
                            onChange={(e) => setCoolDown(e.target.value)}
                            type="number" id="outlined-basic" label="Change Cool Down Hours" variant="outlined"
                            value={coolDown}
                            defaultValue={defaultCoolDown?.coolDownHours || 2}
                            disabled={responseCoolDown.isLoading}
                        />
                        <LoadingButton
                            sx={{ marginTop: "0.4rem" }}
                            fullWidth={true}
                            disabled={responseCoolDown.isLoading}
                            color="secondary"
                            loading={responseCoolDown.isLoading}
                            onClick={updateCoolDownFn}
                            variant="outlined"
                        >
                            Submit
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>


        </Box >
    );
}
