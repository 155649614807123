import React from 'react'
import { TextField } from '@mui/material'

export default function Input(props) {

    const { name, label, value,error=null, onChange ,type} = props;
    return (
        <TextField
            {...props}
            {...(error && {error:true,helperText:error})}
        />
    )
}