import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetAllUserTransactionsQuery } from "app-redux/query/api";
import Header from "components/header";
import DataGridCustomToolbar from "components/grid/Toolbar";
import { columnTransactions } from "common/tableColumns";
import DropDownPicker from "components/dropDownPicker";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import "./styles.css"

const getDateFilterValue = (search) => {
  const date = search?.split("?")?.find(e => e.includes("date"))?.split("=")[1];

  if (date === "today") return "20";
  if (date === "all") return "10";
  if (date === "last_week") return "30";
  if (date === "last_month") return "40";

}
const getTransactionsFilterValue = (search) => {

  const transactions = search?.split("?")?.find(e => e.includes("transactions"))?.split("=")[1];

  if (transactions === "confirm_transaction")
    return "10"
  if (transactions === "pending_transaction")
    return "20"

}



const Earnings = () => {
  const theme = useTheme();
  var dropdownTimeText = {
    first: "All",
    second: "Today",
    third: "Last Week",
    fourth: "Last Month",
  };
  var dropdownTransactionStatusText = {
    first: "Confirm Transaction",
    second: "Pending Transaction",
  };
  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [startDate, setStartDate] = React.useState("2023-1-10");
  const [endDate, setEndDate] = React.useState("");
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [date, setDate] = React.useState("");
  const [transactionStatus, setTransactionStatus] = React.useState("10");
  const [searchInput, setSearchInput] = useState("");
  const { data, isLoading } = useGetAllUserTransactionsQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort),
    search,
    pending: transactionStatus,
    startDate,
    endDate,
  });

  const isTransactions = location.search.includes("transactions");
  const isDate = location.search.includes("date");


  useEffect(() => {
    if (isTransactions) {
      let filterValue = getTransactionsFilterValue(location.search)
      filterValue && setTransactionStatus(filterValue)
    }
    if (isDate) {
      let value = getDateFilterValue(location.search);
      value && setDate(value)
    }
  }, [isTransactions, isDate])

  const handleWalletClick = (e) => {

    console.log("e => ", e);
    if (e.field !== "walletAddress") return
    navigate(`/user/${e.row?.user?.walletAddress}`)

  }

  const previousParams = (value) => {
    const search = location.search.split("?");

    const isDate = value === "date"
    const isTransactions = value === "transactions"

    const date = search?.find(e => e.includes("date"))
    const transactions = search?.find(e => e.includes("transactions"))

    if (isTransactions) return date ? `?${date}` : ""
    if (isDate) return transactions ? `?${transactions}` : ""

  }

  const handleConfirmTransaction = (event) => {

    const param = previousParams("transactions");
    if (event.target.value == 10) {
      navigate(`?transactions=confirm_transaction${param}`)
    }
    if (event.target.value == 20) {
      navigate(`?transactions=pending_transaction${param}`)
    }

    setTransactionStatus(event.target.value)

  }

  console.log("data => ", data)

  const handleChangeDate = (event) => {
    var date = "";

    const param = previousParams("date");

    if (event.target.value == 20) {
      date = moment(new Date()).format("YYYY-MM-DD");
      navigate(`${param}?date=today`)
    }
    if (event.target.value == 30) {
      var d = new Date(); // today!
      var x = 7; // go back 30 days!
      date = moment(d.setDate(d.getDate() - x)).format("YYYY-MM-DD");
      navigate(`${param}?date=last_week`)
    }
    if (event.target.value == 40) {
      var d = new Date(); // today!
      var x = 30; // go back 30 days!
      date = moment(d.setDate(d.getDate() - x)).format("YYYY-MM-DD");
      navigate(`${param}?date=last_month`)
    }
    if (event.target.value == 10) {
      date = "2023-1-10";
      navigate(`${param}?date=all`)
    }
    setDate(event.target.value);
    setStartDate(date);
    setEndDate(date);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Transactions" subtitle="Entire list of transactions" />

      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="2</Box>0px">
        <DropDownPicker
          handleChange={(event) => handleConfirmTransaction(event)}
          session={transactionStatus}
          dropdownText={dropdownTransactionStatusText}
          width={200}
          ml={0}
          mt={2}
          label={"Transaction Status"}
        />
        <Box ml={2.5}>
          <DropDownPicker
            handleChange={(event) => handleChangeDate(event)}
            session={date}
            dropdownText={dropdownTimeText}
            width={120}
            ml={0}
            mt={2}
            label={"Date"}
          />
        </Box>



      </Box>

      <Box
        mt={2}
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          getRowId={(row) => row._id}
          rows={(data && data.transactions) || []}
          columns={columnTransactions}
          rowCount={(data && data.transactions?.length) || 0}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          isRowSelectable={() => { }}
          onCellClick={(e) => handleWalletClick(e)}
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          // components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
        />
      </Box>
    </Box>
  );
};

export default Earnings;
