import { useState, useEffect } from "react";
import { ethers } from "ethers";
import NonceService from "services/nonce.service";
import { toast } from "react-toastify";

import { Container } from "@mui/material";
import { RootStyle, HeadingStyle, ContentStyle } from "./styles";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { verifySignature } from "app-redux/slices/auth";
function App({ setAuth }) {
  const [haveMetamask, sethaveMetamask] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const { ethereum } = window;
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  useEffect(() => {
    const { ethereum } = window;
    const checkMetamaskAvailability = async () => {
      if (!ethereum) {
        sethaveMetamask(false);
      }
      sethaveMetamask(true);
    };
    checkMetamaskAvailability();
  }, []);

  const connectWallet = async () => {
    try {
      setIsSubmitting(true);
      if (!ethereum) {
        sethaveMetamask(false);
        setIsSubmitting(false);
      }
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      let balance = await provider.getBalance(accounts[0]);
      let bal = ethers.utils.formatEther(balance);
      NonceService.getNonceByAddress(accounts[0]).then(async (res) => {
        const { data } = res;
        const signer = provider.getSigner();
        const signedMessage = await signer.signMessage(data.message);
        let accountsAddress = accounts[0];
        dispatch(verifySignature({ signedMessage, accountsAddress }))
          .unwrap()
          .then((res) => {})
          .catch((err) => {
            toast.error(err.message);
            setIsSubmitting(false);
          });
      });
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      setIsConnected(false);
    }
  };

  return (
    <RootStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          <LoadingButton
            //   fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            onClick={connectWallet}
          >
            {isSubmitting ? "loading..." : "Connect"}
          </LoadingButton>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

export default App;
