import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetActiveSessionsQuery } from "app-redux/query/api";
import Header from "components/header";
import DataGridCustomToolbar from "components/grid/Toolbar";
import { activeSessions } from "common/tableColumns";
import DropDownPicker from "components/dropDownPicker";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import "./styles.css"

var dropdownText = {
  first: "All",
  second: "Active Rounds",
  third: "Completed Rounds",
  fourth: "Paused Rounds",
  fifth: "Cancelled Rounds",
};
var dropdownTimeText = {
  first: "All",
  second: "Today",
  third: "Last Week",
  fourth: "Last Month",
};
const getDateFilterValue = (search) => {
  const date = search?.split("?")?.find(e => e.includes("date")).split("=")[1];

  if (date === "today") return "20";
  if (date === "all") return "10";
  if (date === "last_week") return "30";
  if (date === "last_month") return "40";

}
const getRoundsFilterValue = (search) => {

  const rounds = search?.split("?")?.find(e => e.includes("rounds")).split("=")[1]

  if (rounds === "all")
    return "10"
  if (rounds === "active")
    return "20"
  if (rounds === "completed_rounds")
    return "30"
  if (rounds === "paused_rounds")
    return "40"
  if (rounds === "cancelled_rounds")
    return "50"
}

const GameSessions = () => {
  const theme = useTheme();

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [session, setSession] = React.useState("10");
  let location = useLocation();
  const navigate = useNavigate();
  const [date, setDate] = React.useState("");
  const [startDate, setStartDate] = React.useState("2023-1-10");
  const [endDate, setEndDate] = React.useState("");
  const [searchInput, setSearchInput] = useState("");
  const { data, isLoading } = useGetActiveSessionsQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort),
    search,
    session,
    startDate,
    endDate,
  });
  useEffect(() => {
    setSearch(searchInput);
  }, [searchInput]);

  const isRounds = location.search.includes("rounds");
  const isDate = location.search.includes("date");


  useEffect(() => {
    if (isRounds) {
      let filterValue = getRoundsFilterValue(location.search)
      filterValue && setSession(filterValue)
    }
    if (isDate) {
      let value = getDateFilterValue(location.search)
      value && setDate(value)
    }
  }, [isRounds, isDate])

  const previousParams = (value) => {
    const search = location.search.split("?");

    const isDate = value === "date"
    const isRounds = value === "rounds"

    const date = search?.find(e => e.includes("date"))
    const rounds = search?.find(e => e.includes("rounds"))

    if (isRounds) return date ? `?${date}` : ""
    if (isDate) return rounds ? `?${rounds}` : ""

  }


  const handleChange = (event) => {
    const param = previousParams("rounds");

    if (event.target.value === 10)
      navigate(`?rounds=all${param}`)
    if (event.target.value === 20)
      navigate(`?rounds=active${param}`)
    if (event.target.value === 30)
      navigate(`?rounds=completed_rounds${param}`)
    if (event.target.value === 40)
      navigate(`?rounds=paused_rounds${param}`)
    if (event.target.value === 50)
      navigate(`?rounds=cancelled_rounds${param}`)

    setSession(event.target.value);
  };

  const handleWalletClick = (e) => {
    console.log("e => ", e);
    if (e.field !== "walletAddress") return
    navigate(`/user/${e.row?.user?.walletAddress}`)

  }
  const handleChangeDate = (event) => {
    var date = "";

    const param = previousParams("date");

    if (event.target.value == 20) {
      date = moment(new Date()).format("YYYY-MM-DD");
      navigate(`${param}?date=today`)
    }
    if (event.target.value == 30) {
      var d = new Date(); // today!
      var x = 7; // go back 30 days!
      date = moment(d.setDate(d.getDate() - x)).format("YYYY-MM-DD");
      navigate(`${param}?date=last_week`)
    }
    if (event.target.value == 40) {
      var d = new Date(); // today!
      var x = 30; // go back 30 days!
      date = moment(d.setDate(d.getDate() - x)).format("YYYY-MM-DD");
      navigate(`${param}?date=last_month`)
    }
    if (event.target.value == 10) {
      date = "2023-1-10";
      navigate(`${param}?date=all`)
    }
    setDate(event.target.value);
    setStartDate(date);
    setEndDate(date);
  };
  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="Rounds"
        subtitle="All active and in-active rounds "
      />
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
        <DropDownPicker
          handleChange={(event) => handleChange(event)}
          session={session}
          dropdownText={dropdownText}
          width={120}
          ml={0}
          mt={2}
          label={"Rounds"}
        />
        <DropDownPicker
          handleChange={(event) => handleChangeDate(event)}
          session={date}
          dropdownText={dropdownTimeText}
          width={120}
          ml={0}
          mt={2}
          label={"Date"}
        />
      </Box>
      <Box
        mt={2}
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data?.rounds) || []}
          columns={activeSessions}
          rowCount={search == "" ? data?.total : data?.rounds?.length}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          isRowSelectable={() => { }}
          onCellClick={(e) => handleWalletClick(e)}
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          // components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
        />
      </Box>
    </Box>
  );
};

export default GameSessions;
