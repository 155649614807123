import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
  Grid,
} from "@mui/material";

import FlexBetween from "common/styles";
import Header from "components/header";
import moment from "moment";
import { useLocation } from "react-router-dom";
import CourseService from "services/course.service";
import TypographyBox from "components/typography";
const RoundDetails = () => {
  const theme = useTheme();
  const [course, setCourse] = useState({});
  const location = useLocation();
  useEffect(() => {
    const fetchCourse = async () => {
      CourseService.getCourseById(location?.state?.round?.course?._id).then(
        (res) => {
          setCourse(res?.data?.course?.courseDetail);
        }
      );
    };
    fetchCourse();
  }, [location?.state?.round?._id]);
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="Round" subtitle="Selected Round Details" />
      </FlexBetween>

      <Box
        gridColumn="span 8"
        gridRow="span 2"
        backgroundColor={theme.palette.background.alt}
        p="1rem"
        borderRadius="0.55rem"
        marginTop={2}
      >
        <Box sx={{ width: "100%", maxWidth: 500 }}>
          <Typography variant="h1" gutterBottom>
            {location?.state?.round?.user?.username != undefined
              ? location?.state?.round?.user?.username
              : location?.state?.round?.user?.walletAddress}
          </Typography>
          <TypographyBox
            heading={"Club Name"}
            subText={course?.club_name}
            bold={"h6"}
          />
          <TypographyBox heading={"City"} subText={course?.city} bold={"h6"} />
          <TypographyBox
            heading={"Country"}
            subText={course?.country}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Date"}
            subText={moment(location?.state?.round?.date).format(
              "MMMM Do YYYY, h:mm:ss a"
            )}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Holes Count"}
            subText={location?.state?.round?.holeCount}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Start Time"}
            subText={moment(location?.state?.round?.startTime).format(
              "MMMM Do YYYY, h:mm:ss a"
            )}
            bold={"h6"}
          />
          <TypographyBox
            heading={"End Time"}
            subText={moment(location?.state?.round?.endTime).format(
              "MMMM Do YYYY, h:mm:ss a"
            )}
            bold={"h6"}
          />
          <TypographyBox
            heading={"Result"}
            subText={location?.state?.round?.result}
            bold={"h6"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RoundDetails;
