const { Box } = require("@mui/material");
const { styled } = require("@mui/system");
export const RootStyle = styled("div")({
  background: "rgb(249, 250, 251)",
  height: "100vh",
  display: "grid",
  placeItems: "center",
});

export const HeadingStyle = styled(Box)({
  textAlign: "center",
});

export const ContentStyle = styled("div")({
  maxWidth: 480,
  padding: 25,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  background: "#fff",
});
