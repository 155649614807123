import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "common/styles";

const StatBox = ({ title, value, increase, icon, description, subtitle, isFlexBetween, admin }) => {
  const theme = useTheme();
  return (<>

    {admin && <Typography
      mt={"2rem"}
      mb={"0.5rem"}
      style={{ marginLeft: '0.2rem' }}
      variant="h6" sx={{ color: theme.palette.secondary[100] }}>
      {title}
    </Typography>}

    <Box
      gridColumn="span 3"
      gridRow="span 1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p={"1.25rem 1rem"}
      flex="1 1 100%"
      backgroundColor={theme.palette.background.alt}
      borderRadius="0.55rem"
    >


      {/* 
      {admin && <Typography
        alignSelf={"center"}
        variant="h3"
        fontWeight="600"
        sx={{ color: theme.palette.secondary[200] }}
      >
        {value}
      </Typography>} */}

      {(isFlexBetween && !admin) && <FlexBetween>
        <Typography variant="h5" sx={{ color: theme.palette.secondary[100] }}>
          {title}
        </Typography>

        <Typography
          variant="h5"
          sx={{ color: theme.palette.secondary[200] }}
        >
          {value}
        </Typography>
      </FlexBetween>}

      {(!isFlexBetween && !admin) && <>  <FlexBetween>
        <Typography
          variant="h6" sx={{ color: theme.palette.secondary[100] }}>
          {title}
          <Typography variant="h5" color={theme.palette.secondary[300]}>
            {subtitle}
          </Typography>
        </Typography>
        {icon}
      </FlexBetween>

        <Typography
          variant="h3"
          fontWeight={"600"}
          sx={{ color: theme.palette.secondary[200] }}
        >
          {value}
        </Typography>
      </>}


      {admin &&
        <Typography
          variant="h3"
          py={"2rem"}
          fontWeight={"200"}
          m={"auto"}
          sx={{ color: theme.palette.secondary[200] }}
        >
          {value}
        </Typography>
      }
    </Box>
  </>
  );
};

export default StatBox;
